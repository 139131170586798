<template>
  <vca-card>
    <h2>{{ $t("network.organizers.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <OrganizerTable />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('network.organizers.popup.edit', { 0: current.name })"
        @close="current = null"
      >
        <OrganizersEdit />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import OrganizerTable from "@/components/network/organizers/OrganizerTable";
import OrganizersEdit from "@/components/network/organizers/OrganizerEdit";
export default {
  name: "NetworkList",
  components: { OrganizerTable, OrganizersEdit },
  created() {
    this.$store.dispatch({ type: "organizers/list" });
  },
  computed: {
    current: {
      get() {
        return this.$store.state.organizers.current;
      },
      set(value) {
        this.$store.commit("organizers/current", value);
      },
    },
  },
};
</script>
