<template>
  <div>
    <OrganizerForm v-model="current" @submit="update" />
    <div class="vca-center">
      <vca-cancel-button
        @click="deleteOrganizer()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </div>
</template>

<script>
import OrganizerForm from "@/components/network/organizers/OrganizerForm";
export default {
  name: "OrganizerEdit",
  components: { OrganizerForm },
  computed: {
    current: {
      get() {
        return this.$store.state.organizers.current;
      },
      set(value) {
        this.$store.commit("organizers/current", value);
      },
    },
  },
  methods: {
    update() {
      if (!confirm(this.$t("network.organizers.messages.update"))) {
        return false;
      }
      this.$store.dispatch({ type: "organizers/update" });
    },
    deleteOrganizer() {
      if (
        confirm(
          this.$t("network.organizers.edit.delete.confirm", {
            0: this.current.name,
          })
        )
      ) {
        this.$store.dispatch({
          type: "organizers/delete",
          data: this.current.id,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.add-city {
  button {
    margin: 5px 0 10px 10px;
  }
}
</style>
